<template>
  <div class="bg-white">
    <b-navbar
      toggleable="lg"
      type="light"
      :variant="y > 50 || visible ? 'white' : 'transparent'"
      :class="{ 'shadow' : y > 50 || visible }"
      sticky
    >
      <b-container>
        <b-navbar-brand :to="{ name: 'home' }">
          <b-img
            fluid
            :src="logoImg"
            alt="TechX Logo"
          />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse">
          <template #default="{ expanded }">
            <feather-icon
              v-if="expanded"
              icon="XIcon"
              size="24"
            />
            <feather-icon
              v-else
              icon="MenuIcon"
              size="24"
            />
          </template>
        </b-navbar-toggle>

        <b-collapse
          id="nav-collapse"
          is-nav
          @show="visible = true"
          @hidden="visible = false"
        >
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{ name: 'home', hash: '#header' }">
              Back Home
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>

    <div id="header">
      <b-container>
        <div class="inner-container">
          <h2 class="title">
            Become a Client
          </h2>
          <h6 class="subtitle">
            Our clients get the best results when they have our team dedicated to their business for extended periods of time. Ready to move forward?
          </h6>
          <b-row>
            <b-col
              cols="lg"
              order-lg="2"
            >
              <b-card>
                <validation-observer ref="formRules">
                  <b-form>
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="name"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Your Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-form-input
                          v-model="emailValue"
                          :state="errors.length > 0 ? false:null"
                          type="email"
                          placeholder="Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Message"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="message"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Message...."
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="dark"
                      type="submit"
                      block
                      :disabled="loadingSubmit"
                      @click.prevent="submit"
                    >
                      {{ loadingSubmit ? 'Sending...' : 'Send Your Idea' }}
                    </b-button>
                  </b-form>
                </validation-observer>
              </b-card>
            </b-col>
            <b-col order-lg="1">
              <b-img
                fluid
                class="d-none d-lg-block"
                :src="heroImg"
                alt="Hero image"
              />
              <b-img
                fluid
                class="d-lg-none"
                :src="heroSmImg"
                alt="Hero image"
              />
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>

    <Footer />
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BCard,
  BFormRow,
  BContainer,
  BRow,
  BCol,
  BButton,
  BImg,
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BNavbarNav,
  BNavItem,
  BCollapse,
  BFormInput,
  BFormGroup,
  BForm,
  BFormTextarea,
} from 'bootstrap-vue'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import { $themeConfig } from '@themeConfig'
import { useWindowScroll } from '@vueuse/core'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Footer from '../layouts/components/Footer.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BFormRow,
    BCard,
    BContainer,
    BRow,
    BCol,
    BButton,
    BImg,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BNavbarNav,
    BNavItem,
    BCollapse,
    ScrollToTop,
    Footer,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BFormTextarea,
  },
  data() {
    return {
      logoImg: require('@/assets/images/logo/logo.svg'),
      heroImg: require('@/assets/images/pages/contact-img.png'),
      heroSmImg: require('@/assets/images/pages/contact-sm-img.png'),
      visible: false,
      loadingSubmit: false,

      name: '',
      emailValue: '',
      message: '',

      required,
      email,
    }
  },
  setup() {
    const { y } = useWindowScroll()

    const { enableScrollToTop } = $themeConfig.layout

    return { y, enableScrollToTop }
  },
  methods: {
    submit() {
      this.$refs.formRules.validate().then(success => {
        if (success) {
          window.location.href = `mailto:hi@techx.id?${this.getEmail()}`
          this.$nextTick(this.resetForm)

          this.$swal({
            title: 'Thank You!',
            text: 'We\'ve received your idea and will get back to you within 24 hours.',
            icon: 'success',
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-dark',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    resetForm() {
      this.name = ''
      this.emailValue = ''
      this.message = ''

      this.$refs.formRules.reset()
    },
    getEmail() {
      const subject = encodeURI(`New Mail from ${this.name}`)
      const body = encodeURI(`Name: ${this.name}\nEmail/Phone: ${this.emailValue}\nMessage: \n\n${this.message}`)
      const foot = encodeURI('\n\n\nMessage sent from TechX Website - Contact Form')
      return `subject=${subject}&body=${body}${foot}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/vue/pages/page-contact.scss';
</style>
